import React from 'react'

const SvgIcon4 = props => (
  <svg viewBox='0 0 54.78 60' {...props}>
    <defs>
      <style>{'.icon4_svg__cls-1{fill:#2b4b67}.icon4_svg__cls-2{fill:#fd5c1f}'}</style>
    </defs>
    <g id='icon4_svg__tuceng_2' data-name='tuceng 2'>
      <g id='icon4_svg__tuceng_1-2' data-name='tuceng 1'>
        <path
          className='icon4_svg__cls-1'
          d='M27.1 60l-.69-.26C20.18 57.21 15.3 53.61 9 46.89 3 40.54-.23 29.67 0 16.27v-3.6l3.19-.88C10 9.92 17 6.49 26.1.66l1-.66 1 .66c8.56 5.43 16.43 9.16 23.4 11.1l3.19.87v3.71c.25 13.94-2.63 24.5-8.33 30.52a52.92 52.92 0 01-18.53 12.86zM2.63 14.64v1.74C2.39 29 5.31 39.26 10.85 45.11c6 6.33 10.56 9.75 16.25 12.11a50.63 50.63 0 0017.44-12.13c5.23-5.52 7.86-15.46 7.62-28.73v-1.75l-1.28-.36c-7.12-2-15.1-5.73-23.73-11.17C18 8.91 10.82 12.37 3.91 14.29z'
          id='icon4_svg__Path'
        />
        <path
          className='icon4_svg__cls-1'
          d='M35.22 45.49l-7.57-6.23-8.09 5.96V30.26h2.61v9.79l5.57-4.1 4.87 4.01v-9.7h2.61v15.23z'
          id='icon4_svg__Path-19'
        />
        <path
          id='icon4_svg__Oval-14'
          className='icon4_svg__cls-2'
          d='M27.56 34.17A10.44 10.44 0 1138 23.74a10.43 10.43 0 01-10.44 10.43zm0-2.61a7.83 7.83 0 10-7.83-7.82 7.82 7.82 0 007.83 7.82z'
        />
        <path
          id='icon4_svg__Oval-14-2'
          data-name='Oval-14'
          className='icon4_svg__cls-2'
          d='M27.56 29a5.22 5.22 0 115.21-5.21A5.22 5.22 0 0127.56 29zm0-2.61A2.61 2.61 0 1025 23.74a2.59 2.59 0 002.56 2.6z'
        />
      </g>
    </g>
  </svg>
)

export default SvgIcon4
