import React from 'react'

const SvgIcon10 = props => (
  <svg viewBox='0 0 79.63 95.32' {...props}>
    <defs>
      <style>
        {
          '.icon10_svg__cls-2,.icon10_svg__cls-3{fill:none;stroke-linecap:round;stroke-width:4px}.icon10_svg__cls-2{stroke:#2b4b67;stroke-miterlimit:10}.icon10_svg__cls-3{stroke:#fd5c1f;stroke-linejoin:round}'
        }
      </style>
    </defs>
    <g id='icon10_svg__tuceng_2' data-name='tuceng 2'>
      <g id='icon10_svg__tuceng_1-2' data-name='tuceng 1'>
        <path
          d='M41 7.75c-18.24 0-33 6.45-33 14.4v52c0 8 14.79 14.4 33 14.4s33-6.45 33-14.4v-52c0-7.95-14.79-14.4-33-14.4z'
          fill='#2b4b67'
          opacity={0.1}
        />
        <path className='icon10_svg__cls-2' d='M36.84 70c-19 0-34.34-6.71-34.34-15' />
        <ellipse className='icon10_svg__cls-2' cx={36.34} cy={16.98} rx={34.34} ry={14.98} />
        <path className='icon10_svg__cls-2' d='M2 18.29l1.08 55.15c0 8.27 15.37 15 34.34 15' />
        <path className='icon10_svg__cls-2' d='M70.69 17v19.7c0 8.27-15.38 15-34.35 15S2 45 2 36.68' />
        <path
          className='icon10_svg__cls-3'
          d='M61.05 55.06l16.58 6.63v7.68a23.58 23.58 0 01-9.92 19.22l-6.66 4.73-6.66-4.73a23.56 23.56 0 01-9.93-19.22v-7.68z'
        />
        <path className='icon10_svg__cls-3' d='M51.73 77.02l5.92-5.93 4.63 4.63 8.08-8.08' />
      </g>
    </g>
  </svg>
)

export default SvgIcon10
