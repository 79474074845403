import React from 'react'

const SvgIcon7 = props => (
  <svg viewBox='0 0 59.41 59.42' {...props}>
    <defs>
      <style>
        {
          '.icon7_svg__cls-1,.icon7_svg__cls-3{fill:none;stroke-width:3px;stroke:#2b4b67}.icon7_svg__cls-1{stroke-miterlimit:10}.icon7_svg__cls-2{fill:#2b4b67}.icon7_svg__cls-3{stroke-linecap:round;stroke-linejoin:round}'
        }
      </style>
    </defs>
    <g id='icon7_svg__tuceng_2' data-name='tuceng 2'>
      <g id='icon7_svg__tuceng_1-2' data-name='tuceng 1'>
        <path className='icon7_svg__cls-1' d='M14.79 42.33h34.7M14.79 49.05h26.57' />
        <path className='icon7_svg__cls-2' d='M7.74 40.33h4v4h-4zM7.74 47.05h4v4h-4z' />
        <path className='icon7_svg__cls-3' d='M1.5 16.1h56.41v41.82H1.5zM57.91 16.1H1.5L12.61 1.5H46.8l11.11 14.6z' />
        <path
          stroke='#fd5c1f'
          strokeLinecap='round'
          strokeMiterlimit={10}
          fill='none'
          strokeWidth={3}
          d='M23.73 1.55l-3.19 12.57v19.75l9.42-3.82 8.91 3.82V14.12L35.46 1.55H23.73z'
        />
      </g>
    </g>
  </svg>
)

export default SvgIcon7
