import styled from 'styled-components'
import { Media } from '@raysync/common/theme'
import bannerImage from './images/zh/file-exchange/banner.png'
import bannerMobileImage from './images/zh/file-exchange/banner-mobile.png'
import bg1 from './images/zh/file-exchange/bg1.png'
import bg2 from './images/zh/file-exchange/bg2.png'

export const Wrapper = styled.div`
  max-width: 1400px;
  width: 1400px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  ${Media.phone`
    max-width:100vw;
    min-width: initial;
    margin: initial;
    padding-right:  0.4rem;
    padding-left:  0.4rem;
  `}
`
export const Container = styled.div`
  p {
    font-size: 14px;
    line-height: 1.5;
  }
  h2 {
    font-size: 36px;
    line-height: 1.2;
  }
  h3 {
    font-size: 18px;
    line-height: 1.2;
  }
  ${Media.phone`
    p {
      font-size: .2rem;
    }
    h2 {
      font-size: .36rem;
    }
    h3 {
      font-size: .3rem;
    }
  `}
`

export const Banner = styled.div`
  width: 100%;
  height: 460px;
  background: url(${bannerImage}) 100% 100%/100% 100%;
  h1 {
    padding-top: 100px;
    font-size: 48px;
    font-weight: 400;
    color: #131415;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    line-height: 1.5;
    color: #252a3a;
    opacity: 0.8;
    margin-bottom: 50px;
    max-width: 600px;
  }
  button {
    width: 180px;
    height: 40px;
    background: #fd5c1f;
    border-radius: 20px;
  }
  ${Media.phone`
    height: 9rem;
    background-image: url(${bannerMobileImage});
    h1 {
      padding-top: 1rem;
      font-size:.48rem;
    }
    button {
      width: 2.7rem;
      height: .6rem;
      line-height: .6rem;
    }
    p { 
      font-size: .24rem;
      margin-bottom: .3rem;
      max-width: 6rem;
    }
  `}
`
export const Section = styled.div`
  padding-top: ${p => (p.top || 0) + 'px'};
  padding-bottom: ${p => (p.bottom || 0) + 'px'};
  ${Media.phone`
    padding-top: ${p => (p.top || 0) / 100 + 'rem'};
    padding-bottom: ${p => (p.bottom || 0) / 100 + 'rem'}; 
  `}
`
export const Title = styled.h2`
  font-size: 36px;
  font-weight: 400;
  color: #252a3a;
  text-align: center;
  ${Media.phone`
    font-size: .36rem;
  `}
`
export const ChallengeWrap = styled.div`
  display: flex;
  margin-top: 68px;
  ${Media.phone`
    display: block ;
     margin-top: .68rem;
  `}
`
export const ChallengeBox = styled.div`
  width: 420px;
  height: 500px;
  background: url(${bg1}) 100% 100%/100% 100%;
  padding: 40px 30px;
  h3 {
    margin-bottom: 18px;
    font-weight: bold;
  }
  p {
    margin-bottom: 30px;
    color: #636772;
    b {
      color: #252a3a;
    }
  }
  ${Media.phone`
    width: 100%;
    height: unset;
    padding: .6rem .3rem;
    h3 {
      margin-bottom: .18rem;
    }
    p {
      margin-bottom: .3rem;
    }
  `}
`

export const ChallengeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const ChallengeItem = styled.div`
  width: 312px;
  margin-left: 10px;
  position: relative;
  transition: all ease-in-out 0.3s;
  img {
    height: 240px;
  }
  > span {
    position: absolute;
    left: 0;
    top: 0;
    width: 120px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    background: #201f26;
    border-radius: 0px 0px 10px 0px;
  }
  > div {
    padding: 26px 22px;
    height: 260px;
    border: 1px solid transparent;
    background: rgba(249, 250, 252, 0.8);
    transition: all linear 0.3s;
    > div {
      display: flex;
      align-items: center;
      h3 {
        font-weight: bold;
        color: #252a3a;
      }
      svg {
        margin: 0 24px 0 12px;
        height: 40px;
        min-width: 40px;
      }
    }
    p {
      margin-top: 10px;
      color: #636772;
    }
  }
  :hover {
    transform: translateY(2px);
    > div {
      background: #ffffff;
      border-color: #f3f6f7;
      box-shadow: 0px 10px 16px 4px rgba(221, 221, 221, 0.3);
    }
  }
  ${Media.phone`
    width: 48%;
    margin-left: 0;
    margin-top: .3rem;
    &:nth-child(2) {
      margin-left: .1rem;
    }
    span {
      width: 1.6rem;
      height: .4rem;
      line-height: .4rem;
      font-size: .18rem;
    }
    img {
      width: 100%;
      height: 2.4rem;
    }
    > div {
      padding: .3rem .05rem 0.3rem;
      height: unset;
      > div {
        font-size: .3rem;
        svg {
          display: none;
        }
        h3 {
          font-size: .3rem;
        }
        p {
          font-size: .2rem;
          margin-top: .24rem;
        }
      }
    }
  `}
`
export const SolutionSection = styled.div`
  height: 660px;
  background: url(${bg2}) 100% 100%/100% 100%;
  padding: 80px 0 100px;
  color: #636772;
  > div > p {
    margin: 20px auto 58px;
    max-width: 672px;
    text-align: center;
  }
  ${Media.phone`
    height: unset;
    background: #f5f7fb;
    padding: .8rem 0 1rem;
      > div > p {
    margin: .2rem auto .58rem;
  }
`}
`

export const SolutionList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const SolutionListItem = styled.div`
  padding: 0 52px 0;
  width: 660px;
  height: 150px;
  background: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  transition: all linear 0.3s;
  h3 {
    font-weight: bold;
    color: #252a3a;
    margin-bottom: 6px;
  }
  p {
    color: #636772;
    min-height: 64px;
  }
  svg {
    min-width: 60px;
    height: 60px;
    margin-right: 50px;
  }
  :hover {
    box-shadow: 0px 10px 16px 4px rgba(221, 221, 221, 0.3);
  }
  ${Media.phone`
    width: 100%;
    height: unset;
    min-height: 1.8rem;
    margin-bottom: .3rem;
    padding: .3rem;
    align-items: flex-start;
    h3 {
      font-size: .28rem;
      margin-bottom: .1rem;
    }
    p {
       min-height:unset;
    }
    svg {
      min-width: .6rem;
      height: .6rem;
      margin-right: .34rem;
    }
  `}
`
export const ArchSection = styled(Wrapper)`
  padding: 80px 0;
  svg {
    display: block;
    width: 800px;
    height: 370px;
    margin: 60px auto 0;
  }
  ${Media.phone`
    padding: .8rem 0;
    svg {
      width: 100%;
      height: 3.3rem;
      margin: .6rem auto 0;
    }
  `}
`
export const ScenesSection = styled.div`
  padding: 80px 0 100px;
  background: #f5f7fb;
  ${Media.phone`
    padding: .8rem 0 1rem;
  `}
`
export const ScenesList = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  ${Media.phone`
    flex-wrap: wrap;
    padding: 0;
  `}
`
export const ScenesItem = styled.div`
  width: 320px;
  height: 280px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: all ease-in-out 0.3s;
  margin-top: 66px;
  svg {
    margin: 64px auto;
    height: 94px;
    max-width: 102px;
  }
  p {
    font-size: 18px;
    color: #636772;
    transition: all ease-in-out 0.3s;
  }
  :hover {
    box-shadow: 0px 0px 30px 0px #f0f1f1;
    p {
      font-weight: bold;
      color: #252a3a;
    }
  }
  ${Media.phone`
    width: 3.2rem;
    height: 2.8rem;
    margin-top:.66rem;
    flex-wrap: wrap;
    svg {
      margin: .64rem auto .5rem;
      height: .96rem;
      max-width: 1.02rem;
    }
    p {
      font-size: .28rem;
    }
  `}
`
