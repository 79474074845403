import React from 'react'

const SvgIcon6 = props => (
  <svg viewBox='0 0 58.22 57' {...props}>
    <defs>
      <style>
        {
          '.icon6_svg__cls-1{fill:none;stroke-miterlimit:10;stroke-width:3px;stroke:#2b4b67}.icon6_svg__cls-2{fill:#2b4b67}'
        }
      </style>
    </defs>
    <g id='icon6_svg__tuceng_2' data-name='tuceng 2'>
      <g id='icon6_svg__tuceng_1-2' data-name='tuceng 1'>
        <path className='icon6_svg__cls-1' d='M15.29 32.76h34.7M15.29 39.48h26.56' />
        <path className='icon6_svg__cls-2' d='M8.24 30.76h4v4h-4zM8.24 37.48h4v4h-4z' />
        <rect className='icon6_svg__cls-1' x={1.5} y={1.5} width={55.22} height={45.11} rx={5} />
        <path
          d='M11.76 27.36L19 17.13a5 5 0 016.92-1.22l6.61 4.57a5 5 0 006.84-1.1l9.1-12.09'
          strokeLinecap='round'
          stroke='#fd5c1f'
          fill='none'
          strokeMiterlimit={10}
          strokeWidth={3}
        />
        <path
          strokeLinecap='round'
          stroke='#2b4b67'
          fill='none'
          strokeMiterlimit={10}
          strokeWidth={3}
          d='M7.18 55.5h43.86'
        />
      </g>
    </g>
  </svg>
)

export default SvgIcon6
