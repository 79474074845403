import React from 'react'

const SvgIcon9 = props => (
  <svg viewBox='0 0 101.42 94' {...props}>
    <defs>
      <style>
        {
          '.icon9_svg__cls-2,.icon9_svg__cls-3{fill:none;stroke-linecap:round;stroke-width:4px}.icon9_svg__cls-2{stroke:#2b4b67;stroke-miterlimit:10}.icon9_svg__cls-3{stroke:#fd5c1f;stroke-linejoin:round}'
        }
      </style>
    </defs>
    <g id='icon9_svg__tuceng_2' data-name='tuceng 2'>
      <g id='icon9_svg__tuceng_1-2' data-name='tuceng 1'>
        <rect x={7.78} y={35.46} width={62.46} height={41.78} rx={3.5} fill='#2b4b67' opacity={0.1} />
        <path
          className='icon9_svg__cls-2'
          d='M28 37.72h71.42M35.12 18.09h57.15M60.25 57.35h32M63.7 2.59v70.27c8.29 0 15-15.73 15-35.14S72 2.59 63.7 2.59s-15 15.73-15 35.13v6.76M28.25 42.15A35.72 35.72 0 1163.7 73.44'
        />
        <rect className='icon9_svg__cls-2' x={2} y={44.48} width={56.57} height={37.71} rx={5.66} />
        <path className='icon9_svg__cls-2' d='M10.21 92h40.15' />
        <path className='icon9_svg__cls-3' d='M43.7 60.68a15.31 15.31 0 00-26.53 0M38 65.89a8.74 8.74 0 00-15.16 0' />
        <circle cx={30.43} cy={71.75} r={3.51} fill='#fd5c1f' />
      </g>
    </g>
  </svg>
)

export default SvgIcon9
