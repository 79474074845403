import React from 'react'

const SvgArch = props => (
  <svg viewBox='0 0 801 383.2' {...props}>
    <defs>
      <style>
        {
          '.arch_svg__cls-1{fill:#f3f6f7}.arch_svg__cls-12,.arch_svg__cls-13,.arch_svg__cls-15,.arch_svg__cls-16,.arch_svg__cls-17,.arch_svg__cls-18,.arch_svg__cls-19,.arch_svg__cls-2,.arch_svg__cls-21,.arch_svg__cls-23,.arch_svg__cls-3,.arch_svg__cls-4{fill:none}.arch_svg__cls-16,.arch_svg__cls-18,.arch_svg__cls-2,.arch_svg__cls-3,.arch_svg__cls-4{stroke:#fd5c1f}.arch_svg__cls-12,.arch_svg__cls-15,.arch_svg__cls-16,.arch_svg__cls-17,.arch_svg__cls-2,.arch_svg__cls-21,.arch_svg__cls-23,.arch_svg__cls-3,.arch_svg__cls-4{stroke-miterlimit:10}.arch_svg__cls-13,.arch_svg__cls-17,.arch_svg__cls-18,.arch_svg__cls-19,.arch_svg__cls-2,.arch_svg__cls-3,.arch_svg__cls-4{stroke-width:2px}.arch_svg__cls-3{stroke-dasharray:7.63 7.63}.arch_svg__cls-4{stroke-dasharray:7.62 7.62}.arch_svg__cls-5{font-size:18px}.arch_svg__cls-11,.arch_svg__cls-5{fill:#fd5c1f}.arch_svg__cls-5{font-family:SourceHanSansCN-Bold-GBpc-EUC-H,Source Han Sans CN}.arch_svg__cls-6{font-size:14px;font-family:SourceHanSansCN-Regular-GBpc-EUC-H,Source Han Sans CN;fill:#636772}.arch_svg__cls-10{fill:#2b4b67}.arch_svg__cls-12,.arch_svg__cls-13,.arch_svg__cls-15,.arch_svg__cls-17{stroke:#2b4b67}.arch_svg__cls-12{stroke-width:1.91px}.arch_svg__cls-13,.arch_svg__cls-18,.arch_svg__cls-19{stroke-linecap:round;stroke-linejoin:round}.arch_svg__cls-15,.arch_svg__cls-16{stroke-width:1.86px}.arch_svg__cls-19{stroke:#636772}.arch_svg__cls-21,.arch_svg__cls-23{stroke:#b4bfd4}.arch_svg__cls-23{stroke-dasharray:9.48 9.48}'
        }
      </style>
    </defs>
    <g id='arch_svg__tuceng_2' data-name='tuceng 2'>
      <g id='arch_svg__tuceng_1-2' data-name='tuceng 1'>
        <rect className='arch_svg__cls-1' x={49.93} y={93.94} width={100} height={80} rx={6.71} />
        <rect className='arch_svg__cls-1' x={187.7} y={93.94} width={100} height={80} rx={6.71} />
        <rect className='arch_svg__cls-1' x={671.45} y={28.44} width={100} height={100} rx={7.5} />
        <rect className='arch_svg__cls-1' x={671.7} y={217.94} width={100} height={80} rx={6.71} />
        <rect className='arch_svg__cls-1' x={255.24} y={217.94} width={80} height={80} rx={6} />
        <rect className='arch_svg__cls-1' x={316.85} y={93.94} width={100} height={80} rx={6.71} />
        <rect className='arch_svg__cls-1' x={384.72} y={217.94} width={130} height={80} rx={7.65} />
        <path className='arch_svg__cls-2' d='M595.44 333.67v3.77h-3.77' />
        <path className='arch_svg__cls-3' d='M584.04 337.44H23.03' />
        <path className='arch_svg__cls-2' d='M19.21 337.44h-3.77v-3.77' />
        <path className='arch_svg__cls-4' d='M15.44 326.05V25.02' />
        <path className='arch_svg__cls-2' d='M15.44 21.21v-3.77h3.77' />
        <path className='arch_svg__cls-3' d='M26.84 17.44h561.01' />
        <path className='arch_svg__cls-2' d='M591.67 17.44h3.77v3.77' />
        <path className='arch_svg__cls-4' d='M595.44 28.83v301.03' />
        <text className='arch_svg__cls-5' transform='translate(522.51 73.82)'>
          {'\u5185\u7F51'}
        </text>
        <text className='arch_svg__cls-6' transform='translate(78.93 163.16)'>
          {'\u7528\u6237\u7AEF'}
        </text>
        <text className='arch_svg__cls-6' transform='translate(195.7 163.16)'>
          {'\u9009\u62E9\u6587\u4EF6\u6295\u9012'}
        </text>
        <text className='arch_svg__cls-5' transform='translate(618.13 73.82)'>
          {'\u5916\u7F51'}
        </text>
        <text className='arch_svg__cls-6' transform='translate(338.85 163.16)'>
          {'\u5BA1\u6838\u7B56\u7565'}
        </text>
        <text className='arch_svg__cls-6' transform='translate(267.24 287.13)'>
          {'\u9010\u7EA7\u5BA1\u6838'}
        </text>
        <text className='arch_svg__cls-6' transform='translate(391.74 287.13)'>
          {'\u6253\u5305\u6587\u4EF6\u81F3\u6295\u9012\u533A'}
        </text>
        <text className='arch_svg__cls-6' transform='translate(405.74 322.4)'>
          {'\u5185\u7F51\u7A7A\u95F4\u5B58\u50A8'}
        </text>
        <text
          transform='translate(328.5 366.47)'
          fontSize={16}
          fill='#2f323e'
          fontFamily='SourceHanSansCN-Bold-GBpc-EUC-H,Source Han Sans CN'
        >
          {'\u6587\u4EF6\u4ECE\u5185\u7F51\u53D1\u5230\u5916\u7F51'}
        </text>
        <text className='arch_svg__cls-6' transform='translate(195.7 54.94)'>
          {'\u7B26\u5408\u62E6\u622A\u6761\u4EF6'}
        </text>
        <text className='arch_svg__cls-6' transform='translate(230.13 195.48)'>
          {'\u7B26\u5408\u5BA1\u6838\u6761\u4EF6'}
        </text>
        <text className='arch_svg__cls-6' transform='translate(422.04 195.48)'>
          {'\u901A\u884C'}
        </text>
        <text className='arch_svg__cls-6' transform='translate(700.45 108.16)'>
          {'\u7528\u6237\u7AEF'}
        </text>
        <text className='arch_svg__cls-6' transform='translate(693.45 287.13)'>
          {'\u76EE\u6807\u8DEF\u5F84'}
        </text>
        <text className='arch_svg__cls-6' transform='translate(679.45 322.4)'>
          {'\u5916\u7F51\u5B58\u50A8\u7A7A\u95F4'}
        </text>
        <rect x={565.06} y={230.84} width={60} height={30} rx={4} fill='#df5127' />
        <text
          transform='translate(581.06 251.66)'
          fill='#fff'
          fontSize={14}
          fontFamily='SourceHanSansCN-Regular-GBpc-EUC-H,Source Han Sans CN'
        >
          {'\u7F51\u95F8'}
        </text>
        <path
          className='arch_svg__cls-10'
          d='M295.05 265.84l-.47-.17c-4.15-1.69-7.4-4.09-11.63-8.57-4-4.23-6.12-11.48-6-20.41v-2.4l2.13-.59c4.49-1.25 9.2-3.53 15.26-7.42l.69-.44.69.44a60.07 60.07 0 0015.6 7.4l2.12.58v2.47c.17 9.29-1.75 16.34-5.55 20.35a35.34 35.34 0 01-12.41 8.58zm-16.32-30.24v.84a3 3 0 010 .32c-.15 8.44 1.8 15.25 5.49 19.15 4 4.22 7 6.5 10.83 8.07a33.63 33.63 0 0011.63-8.08c3.49-3.68 5.24-10.3 5.08-19.15v-1.17l-.86-.24a61.15 61.15 0 01-15.82-7.45c-6.09 3.89-10.88 6.2-15.48 7.48z'
          id='arch_svg__Path'
        />
        <path
          className='arch_svg__cls-10'
          d='M300.46 256.17l-5.05-4.16-5.39 3.97v-9.97h1.74v6.53l3.71-2.73 3.25 2.67v-6.47h1.74v10.16z'
          id='arch_svg__Path-19'
        />
        <path
          id='arch_svg__Oval-14'
          className='arch_svg__cls-11'
          d='M295.35 248.62a7 7 0 117-7 7 7 0 01-7 7zm0-1.74a5.22 5.22 0 10-5.22-5.22 5.22 5.22 0 005.22 5.22z'
        />
        <path
          id='arch_svg__Oval-14-2'
          data-name='Oval-14'
          className='arch_svg__cls-11'
          d='M295.35 245.14a3.48 3.48 0 113.48-3.48 3.48 3.48 0 01-3.48 3.48zm0-1.74a1.74 1.74 0 10-1.74-1.74 1.74 1.74 0 001.74 1.74z'
        />
        <path className='arch_svg__cls-12' d='M438.22 253.89h22.14M438.22 258.18h16.95' />
        <path className='arch_svg__cls-10' d='M433.72 252.61h2.55v2.55h-2.55zM433.72 256.9h2.55v2.55h-2.55z' />
        <path
          className='arch_svg__cls-13'
          d='M429.74 237.16h36v26.68h-36zM465.74 237.16h-36l7.09-9.32h21.82l7.09 9.32z'
        />
        <path
          strokeLinecap='round'
          strokeWidth={2}
          strokeMiterlimit={10}
          stroke='#fd5c1f'
          fill='none'
          d='M443.92 227.87l-2.03 8.02v12.61l6.01-2.45 5.69 2.45v-12.61l-2.18-8.02h-7.49z'
        />
        <path
          className='arch_svg__cls-15'
          d='M378.14 123.57v-13.52a2.68 2.68 0 00-2.68-2.68h-21.41a2.68 2.68 0 00-2.68 2.68v28.1a2.68 2.68 0 002.68 2.68h11.85M359.09 120.45h15.05M359.09 113.99h15.05M359.09 126.48h5.37M359.09 132.52h5.37'
        />
        <path
          className='arch_svg__cls-16'
          d='M366.26 138.02h16.06v5.35h-16.06zM372.76 132.09l-3.53 5.74h10.12l-3.53-5.74h-3.06z'
        />
        <circle className='arch_svg__cls-16' cx={374.29} cy={127.13} r={5.02} />
        <path
          className='arch_svg__cls-10'
          d='M354.71 112.75h2.48v2.48h-2.48zM354.71 119.21h2.48v2.48h-2.48zM354.71 125.24h2.48v2.48h-2.48zM354.71 130.85h2.48v2.48h-2.48z'
        />
        <rect className='arch_svg__cls-1' x={555.06} y={111.44} width={80} height={80} rx={6} />
        <text className='arch_svg__cls-6' transform='translate(574.06 180.65)'>
          {'\u9632\u706B\u5899'}
        </text>
        <path
          className='arch_svg__cls-17'
          d='M249.17 123.57v-13.52a2.68 2.68 0 00-2.67-2.68h-21.41a2.68 2.68 0 00-2.68 2.68v28.1a2.68 2.68 0 002.68 2.68h11.85M230.13 120.45h15.05M230.13 113.99h15.05M230.13 126.48h5.37M230.13 132.52h5.37'
        />
        <path
          className='arch_svg__cls-10'
          d='M225.75 112.75h2.48v2.48h-2.48zM225.75 119.21h2.48v2.48h-2.48zM225.75 125.24h2.48v2.48h-2.48zM225.75 130.85h2.48v2.48h-2.48z'
        />
        <path className='arch_svg__cls-18' d='M237.37 133.26l7.81-7.82 7.82 7.82M245.18 143.44v-18' />
        <circle className='arch_svg__cls-11' cx={99.93} cy={116.48} r={7.54} />
        <path
          className='arch_svg__cls-11'
          d='M115.34 140.22c0 7.13-6.9 4.89-15.41 4.89s-15.41 2.24-15.41-4.89 6.9-12.92 15.41-12.92 15.41 5.79 15.41 12.92z'
        />
        <circle className='arch_svg__cls-11' cx={721.45} cy={52.48} r={7.54} />
        <path
          className='arch_svg__cls-11'
          d='M736.86 76.22c0 7.13-6.9 4.89-15.41 4.89S706 83.35 706 76.22s6.9-12.92 15.41-12.92 15.45 5.79 15.45 12.92z'
        />
        <path className='arch_svg__cls-19' d='M110.61 84.94V62.96h262.31v21.98' />
        <path
          className='arch_svg__cls-19'
          d='M118.48 78.13l-8 8-8-8M422.04 212.79h11.31v-11.31M431.64 210.94l-32.5-32.5M366.22 253.84l8-8-8-8M371.7 245.84h-25.96M545.7 253.84l8-8-8-8M551.18 245.84h-25.96M654.19 253.84l8-8-8-8M659.67 245.84h-25.96M713.45 188.86l8 8 8-8M721.45 194.34v-45.96M312.35 212.79h-11.31v-11.31M335.25 178.44l-32.5 32.5'
        />
        <path
          className='arch_svg__cls-11'
          d='M729.08 238.55h-.38a7 7 0 00-13.83-.85 4.26 4.26 0 00-.61 0 5.64 5.64 0 100 11.28h14.82a5.21 5.21 0 000-10.41zm0 8.73h-14.82a4 4 0 010-7.92 4.11 4.11 0 011.09.16.85.85 0 00.71-.12.86.86 0 00.36-.63 5.32 5.32 0 0110.62.35v.39a.84.84 0 00.31.71.89.89 0 00.77.16 3.63 3.63 0 011-.14 3.52 3.52 0 010 7z'
        />
        <path
          className='arch_svg__cls-11'
          d='M725.11 241.77a.85.85 0 00-1.19.12 3.12 3.12 0 01-2.34 1.4 4 4 0 01-2.59-1.4.85.85 0 00-1.19-.12.84.84 0 00-.12 1.18 5.66 5.66 0 003.89 2 4.74 4.74 0 003.67-2 .84.84 0 00-.13-1.18z'
        />
        <path
          className='arch_svg__cls-10'
          d='M742.33 253.93L734 231a4.93 4.93 0 00-4.57-3.15h-17a4.91 4.91 0 00-4.66 3.37L700.55 254a6.45 6.45 0 00-.93 3.34 6.54 6.54 0 006.54 6.54h30.58a6.54 6.54 0 006.54-6.54 6.45 6.45 0 00-.95-3.41zm-32.05-21.87a2.17 2.17 0 012.07-1.49h17a2.19 2.19 0 012 1.39l6.94 19a6.66 6.66 0 00-1.62-.2h-30.51a6.55 6.55 0 00-1.84.26zm26.46 29.06h-30.58a3.82 3.82 0 010-7.63h30.58a3.82 3.82 0 010 7.63z'
        />
        <path className='arch_svg__cls-10' d='M731.74 257.28a1.86 1.86 0 101.85-1.85 1.85 1.85 0 00-1.85 1.85z' />
        <path
          d='M587.84 130.47a8 8 0 011.55-2.58 5.08 5.08 0 00.64-1s.44 1.65.7 1.65 3.71-4.66 3.69-7.2a12.42 12.42 0 014.33 5.44 3.28 3.28 0 001.22-1.7c1.16 1.42 3 4.13 2.58 7-.26 1.93-1.42 3.48-3.36 4.9.78-7.18-4.13-9.94-4.13-9.94s-.25 3.36-2.32 5.17a5.8 5.8 0 00-2.06 4 3.57 3.57 0 00.13 1.16c-1.42-.91-4.26-3.23-3-6.84z'
          strokeLinejoin='round'
          strokeWidth={2}
          stroke='#fd5c1f'
          fill='none'
        />
        <path
          className='arch_svg__cls-17'
          d='M581.5 139.91h27.12v18H581.5zM581.71 145.85h26.7M581.71 151.85h26.7M595.06 140.27v5.04M595.06 152.39v5.04M589.46 146.39v5.04M600.62 146.39v5.04'
        />
        <path className='arch_svg__cls-21' d='M480.5 360.5h4.7' />
        <path strokeDasharray='9.41 9.41' stroke='#b4bfd4' strokeMiterlimit={10} fill='none' d='M494.61 360.5H791.1' />
        <path className='arch_svg__cls-21' d='M795.8 360.5h4.7v-4.7' />
        <path className='arch_svg__cls-23' d='M800.5 346.33V9.93' />
        <path className='arch_svg__cls-21' d='M800.5 5.2V.5h-4.7' />
        <path strokeDasharray='9.3 9.3' stroke='#b4bfd4' strokeMiterlimit={10} fill='none' d='M786.5.5H9.85' />
        <path className='arch_svg__cls-21' d='M5.2.5H.5v4.7' />
        <path className='arch_svg__cls-23' d='M.5 14.67v336.4' />
        <path className='arch_svg__cls-21' d='M.5 355.8v4.7h4.7' />
        <path strokeDasharray='9.41 9.41' stroke='#b4bfd4' strokeMiterlimit={10} fill='none' d='M14.61 360.5H311.1' />
        <path className='arch_svg__cls-21' d='M315.8 360.5h4.7' />
      </g>
    </g>
  </svg>
)

export default SvgArch
