import React from 'react'

const SvgIcon5 = props => (
  <svg viewBox='0 0 52.86 61' {...props}>
    <defs>
      <style>
        {
          '.icon5_svg__cls-2,.icon5_svg__cls-4{fill:none;stroke-miterlimit:10}.icon5_svg__cls-2{stroke:#2b4b67}.icon5_svg__cls-4{stroke-width:3px}.icon5_svg__cls-2{stroke-linecap:round;stroke-width:2.93px}.icon5_svg__cls-4{stroke:#fd5c1f}.icon5_svg__cls-5{fill:#2b4b67}'
        }
      </style>
    </defs>
    <g id='icon5_svg__tuceng_2' data-name='tuceng 2'>
      <g id='icon5_svg__tuceng_1-2' data-name='tuceng 1'>
        <path
          d='M44.62 27.6V5.81a4.31 4.31 0 00-4.31-4.31H5.81A4.31 4.31 0 001.5 5.81v45.28a4.31 4.31 0 004.31 4.31h19.1'
          strokeWidth={3}
          strokeMiterlimit={10}
          stroke='#2b4b67'
          fill='none'
        />
        <path className='icon5_svg__cls-2' d='M15.56 22.57h21M15.56 12.17h21M15.56 32.29h7.49M15.56 42.02h7.49' />
        <path strokeLinejoin='round' stroke='#fd5c1f' strokeWidth={3} fill='none' d='M25.49 50.88h25.87v8.62H25.49z' />
        <path className='icon5_svg__cls-4' d='M35.95 41.33l-5.68 9.24h16.3l-5.68-9.24h-4.94z' />
        <circle className='icon5_svg__cls-4' cx={38.42} cy={33.34} r={8.08} />
        <rect className='icon5_svg__cls-5' x={6.88} y={10.17} width={4} height={4} rx={2} />
        <rect className='icon5_svg__cls-5' x={6.88} y={20.57} width={4} height={4} rx={2} />
        <rect className='icon5_svg__cls-5' x={6.88} y={30.29} width={4} height={4} rx={2} />
        <rect className='icon5_svg__cls-5' x={6.88} y={39.33} width={4} height={4} rx={2} />
      </g>
    </g>
  </svg>
)

export default SvgIcon5
