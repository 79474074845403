import React from 'react'

const SvgIcon2 = props => (
  <svg viewBox='0 0 44 40' {...props}>
    <defs>
      <style>{'.icon2_svg__cls-1{fill:#c1c7d6}'}</style>
    </defs>
    <g id='icon2_svg__tuceng_2' data-name='tuceng 2'>
      <g id='icon2_svg__tuceng_1-2' data-name='tuceng 1'>
        <path
          className='icon2_svg__cls-1'
          d='M2.85 33.33V20.62a.88.88 0 01.87-.87h35.57a.88.88 0 01.87.87v5.2H43v-5.2a3.71 3.71 0 00-3.72-3.72h-5.35V0h-2.87v16.87H10V0H7.1v16.87H3.72A3.73 3.73 0 000 20.6v12.71A3.72 3.72 0 003.72 37h18.39v-2.82H3.72a.87.87 0 01-.87-.85z'
        />
        <path
          className='icon2_svg__cls-1'
          d='M6.85 27.91a1.89 1.89 0 003.77 0 1.89 1.89 0 10-3.77 0zM12.9 27.91a1.89 1.89 0 003.77 0 1.89 1.89 0 10-3.77 0zM19 27.91a1.89 1.89 0 003.77 0 1.89 1.89 0 10-3.77 0zM28.3 36.71v-4.6a.43.43 0 01.41-.41h3.75v-2.87h-3.75a3.26 3.26 0 00-3.26 3.26v4.6A3.25 3.25 0 0028.71 40h3.75v-2.92h-3.75a.36.36 0 01-.41-.37zm12.44-7.88H37v2.87h3.77a.43.43 0 01.41.41v4.6a.43.43 0 01-.41.42H37V40h3.77A3.26 3.26 0 0044 36.74v-4.6a3.28 3.28 0 00-3.26-3.31z'
        />
        <path className='icon2_svg__cls-1' d='M32.05 33h5.33v2.87h-5.33z' />
      </g>
    </g>
  </svg>
)

export default SvgIcon2
