import React from 'react'

const SvgIcon11 = props => (
  <svg viewBox='0 0 84 94' {...props}>
    <defs>
      <style>
        {'.icon11_svg__cls-2{fill:none;stroke-linecap:round;stroke-width:4px;stroke:#2b4b67;stroke-miterlimit:10}'}
      </style>
    </defs>
    <g id='icon11_svg__tuceng_2' data-name='tuceng 2'>
      <g id='icon11_svg__tuceng_1-2' data-name='tuceng 1'>
        <rect
          x={4.08}
          y={18.54}
          width={74.32}
          height={61.4}
          rx={4.62}
          transform='rotate(90 41.24 49.24)'
          fill='#2b4b67'
          opacity={0.1}
        />
        <path
          className='icon11_svg__cls-2'
          d='M60.87 65.08v18.85A8.09 8.09 0 0152.8 92H10.07A8.09 8.09 0 012 83.93V26.4a8.09 8.09 0 018.07-8.07H52.8a8.09 8.09 0 018.07 8.07v11.82'
        />
        <path
          d='M73.79 31.71a21.84 21.84 0 01-21.38 17.4 22.66 22.66 0 01-3.32-.25V38.39L30.57 54.63l18.52 16.24v-9.12a25.29 25.29 0 0025.15-25.29 25.59 25.59 0 00-.45-4.75z'
          stroke='#fd5c1f'
          strokeLinejoin='round'
          fill='none'
          strokeLinecap='round'
          strokeWidth={4}
        />
        <path
          className='icon11_svg__cls-2'
          d='M60.87 75.67h13.06A8.09 8.09 0 0082 67.6V10.07A8.09 8.09 0 0073.93 2H31.2a8.09 8.09 0 00-8.07 8.07v8.26M12.9 34.64h21.48M12.9 79.13h21.48M12.9 56.88h8.01'
        />
      </g>
    </g>
  </svg>
)

export default SvgIcon11
