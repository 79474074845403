import React from 'react'

const SvgIcon1 = props => (
  <svg viewBox='0 0 40 40' {...props}>
    <defs>
      <style>{'.icon1_svg__cls-1{fill:#c1c7d6}'}</style>
    </defs>
    <g id='icon1_svg__tuceng_2' data-name='tuceng 2'>
      <g id='icon1_svg__tuceng_1-2' data-name='tuceng 1'>
        <path
          className='icon1_svg__cls-1'
          d='M3.64 7.27h1.82V10H3.64zM24.55 7.27h1.82V10h-1.82zM34.55 3.64h1.82v2.73h-1.82zM24.55 33.64h1.82v2.73h-1.82zM20.91 33.64h1.82v2.73h-1.82zM34.55 30h1.82v2.73h-1.82zM30.91 30h1.82v2.73h-1.82zM17.91 20.27L15.64 18a.89.89 0 00-1.28 0l-2.27 2.27a.89.89 0 000 1.28l2.27 2.27a.89.89 0 001.28 0l2.27-2.27a.89.89 0 000-1.28zM15 21.91l-1-1 1-1 1 1z'
        />
        <path
          className='icon1_svg__cls-1'
          d='M39.09 0H10.91a.86.86 0 00-.91.91v2.73H.91a.86.86 0 00-.91.91v34.54a.86.86 0 00.91.91h28.18a.86.86 0 00.91-.91v-2.73h9.09a.86.86 0 00.91-.91V.91a.86.86 0 00-.91-.91zM28.18 38.18H1.82V5.45h26.36zm10-3.63H30v-6.37l5-2.82a1 1 0 00.45-.81V13.64a1 1 0 00-.45-.82L30 10V4.55a.86.86 0 00-.91-.91H11.82V1.82h26.36zM30 26.09v-14l3.64 2.09V24z'
        />
        <path
          className='icon1_svg__cls-1'
          d='M26.09 16L20 10.27a.82.82 0 00-.64-.27h-8.72a.82.82 0 00-.64.27l-6.09 5.82a.82.82 0 00-.27.64v8.36a1 1 0 00.27.64l4 4.36-3.18 3.82 1.45 1.18 3-3.64.82.91a.94.94 0 00.64.28h8.72a.69.69 0 00.64-.28l6.09-6.72a.85.85 0 00.27-.64v-8.36a.82.82 0 00-.27-.64zm-1.54 8.73l-5.64 6.18H11l-.82-.82 2.18-2.64-1.45-1.18-1.82 2.46-3.64-4v-7.64L11 11.82h8l5.55 5.27z'
        />
      </g>
    </g>
  </svg>
)

export default SvgIcon1
