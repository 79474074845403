import React from 'react'

const SvgIcon3 = props => (
  <svg viewBox='0 0 40 40' {...props}>
    <defs>
      <style>{'.icon3_svg__cls-1{fill:#c1c7d6}'}</style>
    </defs>
    <g id='icon3_svg__tuceng_2' data-name='tuceng 2'>
      <g id='icon3_svg__tuceng_1-2' data-name='tuceng 1'>
        <path
          className='icon3_svg__cls-1'
          d='M27.59 12.5a1.34 1.34 0 010 2.68H12.41a1.34 1.34 0 010-2.68zM19.64 6.7a1.34 1.34 0 110 2.68h-7.23a1.34 1.34 0 110-2.68z'
        />
        <path
          className='icon3_svg__cls-1'
          d='M36 20.8h-1.27V9.4a2.24 2.24 0 00-.91-1.8L27.16 1a2.23 2.23 0 00-1.85-1h-16a4 4 0 00-4 4v13.23H4a4 4 0 00-4 4V36a4 4 0 004 4h32a4 4 0 004-4V24.82a4 4 0 00-4-4.02zM27.55 5.14l2 2h-2zM8 4a1.34 1.34 0 011.29-1.32h15.58v4.93a2.23 2.23 0 002.23 2.24h4.95v11h-15.9a1.35 1.35 0 01-.73-.21l-4.18-2.71a4 4 0 00-2.18-.65H8zm29.32 32A1.34 1.34 0 0136 37.32H4A1.34 1.34 0 012.68 36V21.25A1.34 1.34 0 014 19.91h5a1.36 1.36 0 01.73.22L14 22.84a4.05 4.05 0 002.19.64H36a1.34 1.34 0 011.34 1.34z'
        />
      </g>
    </g>
  </svg>
)

export default SvgIcon3
