import React from 'react'

const SvgIcon8 = props => (
  <svg viewBox='0 0 87.97 84' {...props}>
    <defs>
      <style>
        {
          '.icon8_svg__cls-2,.icon8_svg__cls-3{fill:none;stroke-linecap:round;stroke-width:4px}.icon8_svg__cls-2{stroke:#2b4b67;stroke-miterlimit:10}.icon8_svg__cls-3{stroke:#fd5c1f;stroke-linejoin:round}'
        }
      </style>
    </defs>
    <g id='icon8_svg__tuceng_2' data-name='tuceng 2'>
      <g id='icon8_svg__tuceng_1-2' data-name='tuceng 1'>
        <rect x={6.76} y={7.47} width={59.26} height={68.82} rx={4.37} fill='#2b4b67' opacity={0.1} />
        <path
          className='icon8_svg__cls-2'
          d='M56.58 24.91V7.46A5.45 5.45 0 0051.13 2H7.46A5.45 5.45 0 002 7.46v57.31a5.45 5.45 0 005.46 5.46H15'
        />
        <path
          className='icon8_svg__cls-2'
          d='M50 81.72H20.38a5.07 5.07 0 01-5.07-5.07v-38a5.07 5.07 0 015.07-5.07h33.28l8.92-8.92a5.08 5.08 0 013.59-1.49H80.9a5.07 5.07 0 015.1 5.11v14.06M15.68 16.77h27.23'
        />
        <path className='icon8_svg__cls-3' d='M52.58 60.8h32.2L73.36 49.38M84.78 70.59h-32.2L64 82' />
        <path className='icon8_svg__cls-2' d='M21.42 33.73v-2.92a5.06 5.06 0 015.06-5.07h33.59' />
      </g>
    </g>
  </svg>
)

export default SvgIcon8
